import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { PortfolioSearchModel } from "../../../../models/PortfolioSearchModel";
import { PortfolioAppContext } from "../../context/PortfolioAppContext";
import authContext from "../../../auth/context/AuthContext";
import { has } from "lodash";

// This is the hook that is used in the PortfolioList component
// It is used to retrieve portfolios from the backend

export default function usePortfolioSearchQuery(filters: PortfolioSearchModel, followed = false) {
    const { user } = useContext(authContext);
    const { portfolioService } = useContext(PortfolioAppContext);

    const query = useInfiniteQuery(
        ['portfolios', 'list', followed ? 'followed' : filters],
        ctx => {
            const filters = ctx.queryKey[2] as PortfolioSearchModel | 'followed';

            if (filters === 'followed') {
                // Retrieve followed accounts
                return portfolioService!.getFollowedAccounts(ctx.pageParam);
            } else {
                const hasFilters = !!(
                    filters.style ||
                    filters.locations ||
                    filters.title ||
                    filters.businessTitle
                );

                if (!hasFilters) {
                    // List all portfolios when no filters are applied
                    return portfolioService!.list(ctx.pageParam);
                } else {
                    // Search portfolios when any filter is applied
                    return portfolioService!.search(filters, ctx.pageParam);
                }
            }
        },
        {
            onSuccess: (data) => {
            },
            onError: (error) => {
            },
            getNextPageParam: (lastpage, allPages) => {
                if (lastpage.cursor) {
                    // Get the next page using cursor
                    return lastpage.cursor;
                }

                if (lastpage.totalCount) {
                    const currentCount = allPages.reduce((prev, curr) => curr.items.length + prev, 0);

                    if (currentCount < lastpage.totalCount) {
                        // Get the next page using current count
                        return currentCount;
                    }
                }

                return undefined;
            },
            staleTime: 5 * 60 * 1000, // Data considered fresh for 5 minutes
            cacheTime: 10 * 60 * 1000, // Cache kept for 10 minutes
            refetchOnMount: false, // Don't refetch when component mounts if data exists
            refetchOnWindowFocus: false, // Already set
            enabled: !!user,
        }
    );

    return query;
}