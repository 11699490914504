import React, { PropsWithChildren } from 'react'
import useModalRefAndEvents from '../../hooks/useModalRefAndEvents'
import styled from 'styled-components'
import CloseIcon from '../icons/CloseIcon/CloseIcon'
import { createPortal } from 'react-dom';
import FilterSvgIcon from '../icons/FilterSvgIcon/FilterSvgIcon';
import FilterIcon from '../icons/FilterSvgIcon/FilterIcon';

const StyledDialog = styled.dialog<{
    $isOnTop: boolean;
    $isLeftDrawer?: boolean;
    $isOpen: boolean;
}>(({ $isOnTop, $isLeftDrawer, $isOpen }) => ({
    border: 'none',
    background: 'none',
    width: '100%',
    margin: $isOnTop ? '0px' : 'auto',
    padding: $isLeftDrawer ? 0 : '0.5rem',
    pointerEvents: 'none',
    overflow: 'hidden',
    position: $isLeftDrawer ? 'fixed' : 'relative',
    top: $isLeftDrawer ? 0 : undefined,
    left: $isLeftDrawer ? 0 : undefined,
    bottom: $isLeftDrawer ? 0 : undefined,
    height: $isLeftDrawer ? '100vh' : undefined,
    transform: $isLeftDrawer ? `translateX(${$isOpen ? '0' : '-100%'})` : undefined,
    transition: $isLeftDrawer ? 'transform 0.3s ease-in-out' : undefined,

    '&::backdrop': {
        background: 'rgba(0,0,0, 0.1)',
    },

    '@media (max-width: 768px)': {
        width: '100vw',
        maxWidth: 'none',
    }
}));

const DialogContent = styled.div<{
    $width?: string | number;
    $centeredVertically?: boolean;
    $isLeftDrawer?: boolean;
}>(({ $width, $centeredVertically, $isLeftDrawer }) => ({
    backgroundColor: 'white',
    borderRadius: $isLeftDrawer ? '0px 25px 25px 0px' : '15px',
    maxWidth: $isLeftDrawer ? '40vw' : '500px',
    minWidth: $isLeftDrawer ? '300px' : '300px',
    width: $isLeftDrawer ? '85%' : ($width ? '75%' : undefined),
    height: $isLeftDrawer ? '100%' : undefined,
    padding: $isLeftDrawer ? '1rem' : '1rem',
    margin: $isLeftDrawer ? 0 : ($centeredVertically ? 'auto' : '20px auto'),
    boxShadow: $isLeftDrawer ? undefined : '0 0 10px 0 #00000040',
    pointerEvents: 'auto',  // Make dialog content interactive
    display: $isLeftDrawer ? 'flex' : undefined,
    flexDirection: $isLeftDrawer ? 'column' : undefined,

    '&.fullscreen': {
        width: '100vw',
        height: '100vh',
        maxWidth: 'none',
        borderRadius: 0
    }
}));

const DialogHeader = styled.div({
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '.close-icon': {
        cursor: 'pointer'
    }
});

const DialogBody = styled.div<{ $isLeftDrawer?: boolean }>(({ $isLeftDrawer }) => ({
    padding: '1rem',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: $isLeftDrawer ? 'none' : '80vh',
    flex: $isLeftDrawer ? 1 : undefined, // Take remaining space in flex container
    '&::-webkit-scrollbar': {
        display: 'none'
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    'scrollbarWidth': 'none'  /* Firefox */
}));

const DialogFooter = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    width: '100%',
});

export type ModalProps = {
    show?: boolean
    /** If true, will display a X button in the header */
    closable?: boolean
    /** Asking if can close the modal when clicking on the backdrop or the X button (when present) */
    onCloseRequested?: () => boolean
    /** Triggered once the hide animation is finished */
    onHidden?: () => void
    width?: number | string
    centeredVertically?: boolean
    modalName?: string
    isOnTop?: boolean
    /** If true, modal will appear as a drawer from the left side */
    isLeftDrawer?: boolean
    /** Custom width for the left drawer (default is 300px) */
    drawerWidth?: string | number
    headerTitle?: string
    footerElement?: React.ReactNode
}

export default function Modal({
    show,
    children,
    footerElement,
    headerTitle,
    closable,
    onCloseRequested: onCloseRequest,
    onHidden,
    width,
    centeredVertically,
    modalName = '',
    isOnTop = false,
    isLeftDrawer = false,
    drawerWidth,
}: PropsWithChildren<ModalProps>) {
    const uniqueId = React.useRef(`modal-${Math.random()}`);
    const { divRef } = useModalRefAndEvents(!!show, onHidden, onCloseRequest, uniqueId.current);
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        if (show) {
            // Small delay to ensure animation happens after modal appears
            setTimeout(() => setIsOpen(true), 10);
        } else {
            setIsOpen(false);
        }
    }, [show]);

    if (!show) {
        return null;
    }

    const actualWidth = isLeftDrawer && drawerWidth ? drawerWidth : width;

    return (
        createPortal(<StyledDialog
            key={modalName + uniqueId}
            id={uniqueId.current}
            ref={divRef}
            onClick={(e) => { e.stopPropagation(); }}
            $isOnTop={isOnTop}
            $isLeftDrawer={isLeftDrawer}
            $isOpen={isOpen}
        >
            <DialogContent
                $width={actualWidth}
                $centeredVertically={centeredVertically}
                $isLeftDrawer={isLeftDrawer}
            >
                {(closable) &&
                    <DialogHeader>

                        {headerTitle &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                marginRight: 'auto',
                                gap: '0.5rem',
                            }}>
                                <FilterIcon />
                                <span style={{}}>{headerTitle}</span>
                            </div>
                        }

                        {closable &&
                            <CloseIcon
                                onClick={() => divRef.current?.close()}
                                className="close-icon"
                                aria-label="Close"
                            />
                        }

                    </DialogHeader>
                }

                <DialogBody $isLeftDrawer={isLeftDrawer}>
                    {children}
                </DialogBody>

                {footerElement && <DialogFooter>
                    {footerElement}
                </DialogFooter>}

            </DialogContent>
        </StyledDialog>, document.body)
    )
}

