import React from 'react'
import { PropsIcon } from '../IconProps/IconProps';

const Icon = require('../png/default/db_icon_filter.png');

export default function FiltersIcon({ size = 28 }: PropsIcon) {
    return (
        <img
            src={Icon}
            width={size}
            alt='Filter icon'
        />
    )
}