import React, { createContext, PropsWithChildren, useRef, useState, useContext } from 'react'
import { useInfiniteQuery, InfiniteData } from 'react-query';
import { EventDTO } from 'shared-module'
import { EventsService } from '../services/events.service';
import { ListResult } from '../../../common/types/ListResult';
import AuthContext from '../../../auth/context/AuthContext';

export const EventListContext = createContext({
    data: undefined as InfiniteData<ListResult<EventDTO>> | undefined,
    currentFilters: {} as any,
    bookmarked: false,
    isLoading: false,
    onFilterChange: (filters: any) => { return; },
    onBookmarkChange: (bookmark: boolean) => { return; },
    loadNextPage: () => { return; }
})


export default function EventListProvider({ children }: PropsWithChildren<unknown>) {
    const [filters, setFilters] = useState({});
    const [bookmarked, setBookmarked] = useState(false);
    const service = useRef(new EventsService());
    const { user } = useContext(AuthContext);

    const query = useInfiniteQuery(
        ['events', bookmarked ? { bookmarked } : filters],
        ctx => {
            if (typeof ctx.queryKey[1] === 'object' && Object.keys(ctx.queryKey[1]).length !== 0)
                return service.current.search(ctx.queryKey[1], ctx.pageParam);
            else
                return service.current.getList(ctx.queryKey[1], ctx.pageParam);
        }, {
        getNextPageParam: (lastpage, allPages) => {
            if (lastpage.cursor)
                return lastpage.cursor;
            if (lastpage.totalCount) {
                const currentCount = allPages.reduce((prev, curr) => curr.items.length + prev, 0);
                if (currentCount < lastpage.totalCount)
                    return currentCount;
            }
            return undefined
        },
        onSuccess: (data) => {

        },
        staleTime: 5 * 60 * 1000, // Data considered fresh for 5 minutes
        cacheTime: 10 * 60 * 1000, // Cache kept for 10 minutes
        refetchOnMount: false, // Don't refetch when component mounts if data exists
        refetchOnWindowFocus: false, // Already set
        enabled: !!user,
    });

    function handleFilterChange(filters: any) {
        setFilters(filters);
        setBookmarked(false);
    }

    function handleOnBookmarkChange(bookmark: boolean) {
        if (bookmark) {
            setFilters({});
        }
        setBookmarked(bookmark);
    }

    return (
        <EventListContext.Provider value={{
            currentFilters: filters,
            data: query.data,
            isLoading: query.isLoading,
            bookmarked,
            onBookmarkChange: handleOnBookmarkChange,
            onFilterChange: handleFilterChange,
            loadNextPage: () => {
                if (query.data?.pages[0]?.cursor)
                    query.fetchNextPage();
            }

        }}>
            {children}
        </EventListContext.Provider>
    )
}