import React, { createContext, PropsWithChildren } from 'react'
import { useMutation } from 'react-query'
import { MarketplaceSellerService } from '../../services/marketplaceSeller.service'
import { useToast } from '../../hooks/useToast'
import { useTranslation } from 'react-i18next'

export const marketPlaceSellerContext = createContext({
    getOnboardingLink: (redirectUrl?: string) => { return; },
    isLinkLoading: true,
});

enum StripeOnboardingStatus { refresh = 'refresh', redirect = 'redirect' };



export function MarketplaceSellerProvider({ children }: PropsWithChildren) {
    const { toast } = useToast();
    //@ts-ignore
    const { t } = useTranslation('common');
    const onboardingLinkMutation = useMutation(MarketplaceSellerService.getOnboardingLink);

    const getOnboardingLink = async (redirectUrl?: string) => {
        if (onboardingLinkMutation.isLoading)
            return;

        const refreshUrl = new URL(redirectUrl ?? window.location.href);
        const returnUrl = new URL(redirectUrl ?? window.location.href);
        refreshUrl.searchParams.set('stripeStatus', StripeOnboardingStatus.refresh);
        returnUrl.searchParams.set('stripeStatus', StripeOnboardingStatus.redirect);

        try {

            const result = await onboardingLinkMutation.mutateAsync({
                refreshUrl: refreshUrl.toString(),
                returnUrl: returnUrl.toString()
            });

            if (result)
                window.location.href = result;
        } catch {
            toast({
                message: t('internalServer.error'),
                type: 'danger'
            });
        }
    }

    return (
        <marketPlaceSellerContext.Provider value={{
            getOnboardingLink,
            isLinkLoading: onboardingLinkMutation.isLoading,
        }}>
            {children}
        </marketPlaceSellerContext.Provider>
    )
}