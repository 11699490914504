import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoUserAttribute,
    ISignUpResult,
    ICognitoUserData,
} from 'amazon-cognito-identity-js';
import { RegisterDTO } from 'shared-module/dist/types';
import config from '../../../config/serverless-config.json';
import { waitFor } from '../../../utils/wait';

class CognitoService {
    private cognitoUser: CognitoUser | null = null;
    private initiated = false;
    private static _instance: CognitoService;

    private constructor() {
        this.init().finally(() => {
            this.initiated = true;
        });
    }

    public static get instance() {
        if (!this._instance) {
            this._instance = new CognitoService();
        }

        return this._instance;
    }

    getCognitoUser = () => {
        return this.cognitoUser;
    }

    private init() {
        return new Promise<void>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });

            this.cognitoUser = userPool.getCurrentUser();

            if (this.cognitoUser === null)
                return resolve();

            this.cognitoUser.getSession((err: any, session: CognitoUserSession) => {
                if (err) {
                    console.error('getSession', err.message || JSON.stringify(err));
                    return reject(err);
                }
                return resolve();
            });

        });
    }

    getSession = async () => {
        if (!this.initiated)
            await waitFor(() => this.initiated);

        return new Promise<CognitoUserSession | null>((resolve, reject) => {
            if (!this.cognitoUser)
                return resolve(null);

            this.cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
                if (err) {
                    if (err.message === 'Refresh Token has expired') {
                        console.warn('Refresh Token has expired');
                        this.cognitoUser = null; // Clear the expired user
                        return resolve(null);
                    }
                    console.error(err);
                    return resolve(null);
                }

                return resolve(session);
            });
        });
    }

    async login(email: string, password: string) {
        const userPool = new CognitoUserPool({
            UserPoolId: config.cognitoUserPoolId,
            ClientId: config.cognitoClientId
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: email.toLowerCase(),
            Password: password
        });

        const userData = {
            Username: email.toLowerCase(),
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        this.cognitoUser = cognitoUser;
        return new Promise<{ session?: CognitoUserSession, userConfirmationNecessary?: boolean, newPasswordRequired?: true }>((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session, userConfirmationNecessary) => {
                    this.cognitoUser = cognitoUser;

                    if (userConfirmationNecessary) {
                        console.log('userConfirmationNecessary')
                    }

                    resolve({ session, userConfirmationNecessary });
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.
                    delete userAttributes.email_verified;
                    resolve({ session: undefined, newPasswordRequired: true });
                },
                onFailure: error => {

                    if (error.message === 'User is not confirmed.') {
                        cognitoUser.resendConfirmationCode(() => { });
                        resolve({ session: undefined, userConfirmationNecessary: true });
                    }
                    reject(error);
                }

            });
        });
    }

    register = (values: RegisterDTO) => {

        return new Promise<ISignUpResult | undefined>((resolve, reject) => {
            const attributeList = [
                new CognitoUserAttribute({
                    Name: 'email',
                    Value: values.email.toLowerCase()
                })
            ];
            const self = this;
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            userPool.signUp(values.email.toLowerCase(), values.password, attributeList, [], async (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                self.cognitoUser = result!.user;

                resolve(result);
            });
        })
    }

    sendNewCode = async (email: string) => {
        return new Promise<void>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            const user = new CognitoUser({
                Username: email.toLowerCase(),
                Pool: userPool,
            });
            user.resendConfirmationCode(function (err, result) {
                if (err) {
                    console.error(err)
                    reject(err)
                    return;
                }
                resolve(result);
            });
        });
    }

    confirmEmail = async (code: string, email: string) => {
        return new Promise<string>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            const userData = {
                Username: email.toLowerCase(),
                Pool: userPool,
            } as ICognitoUserData;


            const user = new CognitoUser(userData);

            user.confirmRegistration(code, true, function (err, result) {
                if (err) {
                    console.error(err)
                    reject(err)
                    return;
                }

                resolve(result);
            });
        });
    }

    forgotPassword = async (email: string) => {
        return new Promise<void>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            const cognitoUser = new CognitoUser({
                Username: email.toLowerCase(),
                Pool: userPool,
            });

            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    resolve();
                },
                onFailure: function (err) {
                    reject(err);
                },
            });
        });

    }

    completeNewPasswordChallenge = async (email: string, newPassword: string) => {
        return new Promise<{ session: CognitoUserSession, confirmationNecessary?: boolean }>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            const cognitoUser = new CognitoUser({
                Username: email.toLowerCase(),
                Pool: userPool,
            });

            (this.cognitoUser ?? cognitoUser).completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: (session, confirmationNecessary) => resolve({ session, confirmationNecessary }),
                onFailure: reject
            })
        })
    }

    resetPassword = async (code: string, email: string, newPassword: string) => {
        return new Promise<void>((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: config.cognitoUserPoolId,
                ClientId: config.cognitoClientId
            });
            const cognitoUser = new CognitoUser({
                Username: email.toLowerCase(),
                Pool: userPool,
            });
            cognitoUser.confirmPassword(code, newPassword, {
                onSuccess: () => resolve(),
                onFailure: reject
            })
        })
    }

    async logout() {
        return new Promise<void>(resolve => this.cognitoUser?.signOut(resolve));
    }
}

export default CognitoService;