import React, { PropsWithChildren, useEffect, useState } from "react";
import { UserModel } from "../../../models/UserModel";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/auth.service";
import { useToast } from "../../common/hooks/useToast";
import useMixpanel from "../../common/hooks/useMixPanel";
import CognitoService from "../services/cognito.service";
import wait from "../../../utils/wait";
import { SessionStorage } from "../../../utils/sessionStorage";
import { useTranslation } from "react-i18next";
import { MarketplaceBuyerService } from "../../common/services/marketplaceBuyer.service";
import { useQueryClient } from "react-query";
import { PortfolioService } from "../../portfolio/services/portfolio.service";

export default function AuthProvider({ children }: PropsWithChildren<unknown>) {
    //@ts-ignore
    const { t } = useTranslation(['toasts']);
    const [user, setUser] = useState<UserModel>();
    const [isLoading, setIsLoading] = useState(true);
    const { toast } = useToast();
    const navigate = useNavigate();
    const mixpanel = useMixpanel();

    const queryClient = useQueryClient();

    useEffect(() => {
        (async () => {
            try {
                const session = await CognitoService.instance.getSession();

                if (session) {
                    const user = await refetchUser();
                    mixpanel.identify(user?.userId ?? user?.portfolio?.userId);
                    if (user?.portfolio?.username) {
                        mixpanel.register({
                            profileUrl: `${window.location.origin}/${user?.portfolio?.username}`
                        });
                    }

                    mixpanel.track('Auth - User opens the app');
                }
            } catch (err: any) {
                console.error('Error during session retrieval:', err); // Log the error for debugging
                if (err.code === 'NotAuthorizedException' || err.message.includes('Refresh Token has expired')) {
                    setUser(undefined);
                    navigate('/login');
                }
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    async function login(email: string, password: string) {
        try {
            const result = await CognitoService.instance.login(email, password);

            if (result.userConfirmationNecessary) {
                navigate(`/confirm-email?email=${encodeURIComponent(email)}`);
            } else if (result.newPasswordRequired) {
                navigate(`/force-reset-password?email=${encodeURIComponent(email)}`);
            } else if (result.session) {
                try {
                    await wait(200);
                    const user = await refetchUser();
                    mixpanel.identify(user?.userId ?? user?.portfolio?.userId);
                    if (user?.portfolio?.username) {
                        mixpanel.register({
                            profileUrl: `${window.location.origin}/${user?.portfolio?.username}`
                        });
                    }
                    mixpanel.track('Auth - Login');

                    setTimeout(() => {
                        MarketplaceBuyerService.assignGuestTransactionToUser();
                        const redirectTo = SessionStorage.getRedirectRoute();
                        SessionStorage.clearRedirectRoute();
                        if (redirectTo !== null)
                            navigate(redirectTo, { replace: true });
                    }, 100);

                    navigate('/');
                } catch (err) {
                    console.error('Error during user refetch:', err); // Log the error for debugging
                    navigate('/login');
                }
            }
        } catch (err) {
            console.error('Error during login:', err); // Log the error for debugging
            // toast({ type: 'error', message: t('toasts:loginError') });
        }
    }

    async function logout() {
        await CognitoService.instance.logout();
        setUser(undefined);

        mixpanel.track('Auth - Logged out');
        mixpanel.identify(undefined);

        toast({ type: 'success', message: t('toasts:logout') });
        navigate('/login');
    }


    async function refetchUser() {
        try {
            const { user } = await authService.getMe();
            setUser(user);

            if (user?.portfolio)
                localStorage.setItem('avatarUrl', user.portfolio.avatarCroppings?.circle?.url ?? user.portfolio.avatarUrl);

            queryClient.invalidateQueries(['portfolios', 'events', 'announcements']);

            return user;
        } catch (err: any) {
            console.error('Error during user refetch:', err); // Log the error for debugging
            if (err.message.includes('Refresh Token has expired')) {
                setUser(undefined);
                navigate('/login');
            } else {
                setUser(undefined);
            }
        }
    }

    async function markGuidedTourAsRead(tour: keyof UserModel['onboarding']) {
        if (!user) return;

        const timestamp = new Date().toISOString();
        const updatedOnboarding = { ...user.onboarding, [tour]: timestamp };
        setUser({ ...user, onboarding: updatedOnboarding });

        try {
            await new PortfolioService().updateOnboarding(user.userId, { [tour]: timestamp });
        } catch {
            setUser(undefined);
        }
    }

    return (
        <AuthContext.Provider value={{
            user,
            logout,
            isLoading,
            refetchUser,
            markGuidedTourAsRead,
            login,
        }}>
            {children}
        </AuthContext.Provider>
    )
}